/*-----Variables-----*/
@import 'variables';

#breadcrumbs {
    background-color: #e9ecef;
}

.alert.header-message {
    border-radius: 0 !important;
}

/*.body {
	background-color: $white !important;
}*/






.align-content-center {
	display: flex;
  	align-items: center;
}