@import "../global";
@import "global";
/*-----Responsive-----*/
@import 'responsive';

/*-----Variables-----*/
@import 'variables';

/*-----use fonts-----*/
@import "fonts";

// Bootstrap
@import '~bootstrap/scss/bootstrap';


body {
	font-family: $font-body;
	font-size: 0.92rem;
	color: $body-color;
	&::-webkit-scrollbar {
	    width: 0.3em;
	}
	 
	&::-webkit-scrollbar-track {
	    -webkit-box-shadow: none;
	    box-shadow: none;
    	background: rgba(101,101,101,0.7);
	}
	 
	&::-webkit-scrollbar-thumb {
	  background-color: #869860;
	  outline: none;
	}
}
section {
	background-color: #f4dacd;
	padding-top: 7.42857143em;
  	padding-bottom: 7.42857143em;
}
img {
	max-width: 100%;
}
input {
	padding: 0 16px;
	&:focus {
		outline:1px solid $dark-2;
	}
}
// Overlays
[data-scrim-bottom] {
  position: relative;
  &:before {
  	position: absolute;
  	content: '';
  	width: 100%;
  	height: 80%;
  	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  	/* FF3.6+ */
  	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, #252525));
  	/* Chrome,Safari4+ */
  	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  	/* Chrome10+,Safari5.1+ */
  	background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  	/* Opera 11.10+ */
  	background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  	/* IE10+ */
  	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #252525 100%);
  	bottom: 0;
  	left: 0;
  	z-index: 1;
  	backface-visibility: hidden;
  }
}

$scrim: 10;

@for $i from 1 through $scrim {
    [data-scrim-bottom="#{$i}"]:before{
        opacity: ($i / 10);
    }
}

[data-overlay] {
  position: relative;
  &:before {
  	position: absolute;
	content: '';
	background: #252525;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
  }
}
$overlay: 10;

@for $i from 0 through $overlay {
    [data-overlay="#{$i}"]:before{
        opacity: ($i / 10);
    }
}


// Spacing
.next-line {
	display: block;
    clear: both;
    @include media($xs){
    	display: initial;
      	clear: none;
    }
}
hr.light {
	border-color: #ddd;
}

/*-----Menu-----*/
.navbar {
	padding:1rem;
	.logo {
		max-height: 5rem;
	}
	.nav-item {
		.dropdown-toggle {
			&:after {
				display: none;
			}
		}
		.dropdown-menu {
			border: 0;
		    border-top: 2px solid $primary;
		    border-radius: 0;
		    padding: 0;
		    .dropdown-item {
		    	color: rgba(0, 0, 0, 0.5);
		    	padding: 0.5rem 1.5rem;
		    	font-size: 0.9em;
    			text-transform: uppercase;
    			font-family: $font-regular;
    			&:not(:last-child){
    				border-bottom: 1px solid #d5d5d5;
    			}
    			&:hover {
    				background: transparent;
    				color: $primary;
    			}
		    }
		}
		.c-green {
			color: $secondary-2;
			font-family: $font-bold;
		}
		.c-primary {
			color: $primary;
			font-family: $font-bold;
		}
	}
	.nav-link {
		font-family: $font-regular;
		text-transform: uppercase;
		font-size: 0.95em; 
	}
	.nav-secondary {
		font-size: 0.8em !important; 
		}
	&-light {
		.navbar-nav {
			.show, .active {
				> .nav-link {
					color: $primary;
				}
			}	
			.nav-link {
				&:hover {
					color: $primary;
				}
				&.show, &.active {
					color: $primary;
				}
			}
		}
	}
}

/*Height*/
.height{
	&-10{
	height: 10vh;
	}
	&-20{
	height: 20vh;
	}
	&-30{
	height: 30vh;
	}

	&-40{
	height: 40vh;
	}

	&-50{
	height: 50vh;
	}

	&-60{
	height: 60vh;
	}

	&-70{
	height: 70vh;
	}

	&-80{
	height: 80vh;
	}

	&-90{
	height: 90vh;
	}
	&-100{
	height: 100vh;
	}

} 

.rad20 {
	border-radius: 20px;
}

.med {
	font-size: 90%;
}

/*Colors*/

.color {

	&-pink {
	color: #ef005d !important;
	}

	&-green {
		color: $secondary-2 !important;
	}

	&-muted {
		color: $muted !important;
	}

	&-bod {
		color: $body-color !important;
	}

	&-primary-2 {
		color: $primary-2 !important;
	}
	&-yellow {
		color: #e3a944 !important;
	}
	&-primary-3 {
		color: $primary-3 !important;
	}

}

/*Typography*/ 

.type {
	&-uppercase {
		text-transform: uppercase;
	}
}


/*-----Cards-----*/
.login-card {
	.card {
		border:0;
	}
	.card-header {
		padding: 1.5rem 1.25rem;;
		background: $white;
	}
}

.no-border {
	border:0 !important;
}

.blog-card {
	padding: 0;
	background: #f4dacd;
	.card-body {
		padding:0;
	}

	a {
		.card-desc {

			* {
				color: $body-color;
			}
			i {
				color:#afafaf;
			}
		}
		&:hover {
			text-decoration: none;
			* {
				color: $secondary-2;
			}
		}
	}
}

.gardener-img {
	height:120px; 
	/*width:inherit;*/ 
	object-fit:cover;
	border-radius: 20px;
}

[data-scrim-bottom]:before {
	border-radius: 20px;
}

.garden-card {
	border-radius:20px;
	background: #f4dacd;
	border:0;
	padding:0;
	.card-body {
		padding:0;
		.card-img {
			position:relative;
			.card-memory {
				position:absolute;
				bottom:0;
				z-index: 2;
				color: $white;
				padding-right:0.6rem;
				padding-left:0.6rem;
				border-radius: 20px;
				width: 80%;

				p {
					font-size: 11px;
				}
			}

		}
	}
	.card-desc {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top:1em;
		background: #f4dacd;
		.cat-icon {
			display: inline-flex;
			max-width: 12px;
    		margin-right: 0.3rem;
		}
		.cat-name {
			display: inline-flex;
			font-size: 13px;
		}
		.card-name {
			font-family: $font-extrabold;
			font-size: 14px;
		}
		&-noborder {
			border-bottom: 0px !important;
		}
		padding-bottom:1em;
	}
	a {
		.card-desc {
			* {
				color: $body-color;
			}
			i {
				color:#afafaf;
			}
		}
		&:hover {
			text-decoration: none;
			* {
				color: $secondary-2;
			}
		}
	}
}
.events-widget{
	background-image: linear-gradient(to bottom, rgba(78,125,214,1), rgba(90,128,201,1));
	padding:1.7rem;
	margin-bottom:1em;
	border-radius: 20px;
	color: white;
	a {
		color: $primary;
		text-transform: uppercase;
		font-family: $font-bold;
		margin-top: 1em;
		font-size: 0.9em;
	}
	.event-calendar {
		padding-top: 0.8em;
		padding-bottom: 0.8em;
		border-bottom: 1px solid #eee;
		&:last-child {
			margin-bottom: 1em;
		}
		
		i {
			color: $primary-3;
			font-size: 0.9em;
		}
		.event-name {
			font-family: $font-bold;
			margin-bottom: 0.3rem;
		}
		.event-date {
			font-size: 0.9em;
		}
		.event-link {
			a {
				font-family: $font-body;
				font-size: 0.9em;
				color: $muted;
				text-transform: capitalize;
			}
		}
		.gardener-name {
			margin-bottom: 0.3rem;
			font-size: 0.9em;
		}
		
	}
}
.resources-widget{
	padding:1.7rem;
	margin-bottom:3em;
	border-radius: 20px;
	hr {
		border-color: $muted;
	}
	a {
		color: $primary;
		text-transform: uppercase;
		font-family: $font-bold;
		margin-top: 1em;
		font-size: 0.9em;
	}
	.resources-calendar {
		padding-top: 1em;
		padding-bottom: 0em;
		border-bottom: 1px solid $muted;
		&:last-child {
			margin-bottom: 1em;
		}
		
		i {
			color: $secondary-2;
			font-size: 0.9em;
		}
		img {
			margin-bottom: 0.8em;
			max-width: 85%;
		}
		.resources-name {
			font-family: $font-bold;
			margin-bottom: 0.3rem;
		}
		.resources-cat {
			color: $muted-2;
			font-size: 0.9em;
		}
		.resources-place {
			color: $muted-2;
			font-size: 0.9em;
		}
		.resources-link {
			a {
				font-family: $font-body;
				font-size: 0.9em;
				color: $muted-2;
				text-transform: lowercase;
			}
		}
		
	}
}
/*-----List------*/
.list-social {
	list-style: none;
	margin-bottom: 0;
	padding:0;
	display: flex;
	justify-content: center;
	li {
		margin-left: 1.5rem;
	}
	a {
		
		&:hover {
			color: $primary;
		}
	}
}
/*-----Buttons-----*/
a {
	font-family: $font-regular;
}

.btn {
	border-radius:20px;
	padding: 0.7rem 2rem;
    font-size: 0.75rem;
    line-height: 1.4;
    text-transform: uppercase;
    font-family: $font-regular;
    letter-spacing: 2px;
    
	&-facebook {
		background: $bg-facebook;
		border-color: $bg-facebook;
		color: $white !important;
		&:hover {
			background: #4261b0;
			border-color: #4261b0;
		}
	}
	&-google {
		background: $bg-google;
		border-color: $bg-google;
		color: $white !important;
	}
	&-secondary {
		&.active {
		background: $secondary-2 !important;
		border:1px solid $secondary-2 !important;
		}
	}
	&-primary {
		background-image: linear-gradient(to top, rgba(165,32,45,1), rgba(220,63,83,1));
    	border: 0px;
	}
	&-green {
		background-image: linear-gradient(to bottom, rgba(100,148,0,1), rgba(61,90,0,1));
		border: 0px;
	}
	&-lg {
		padding: 0.4rem 4rem;
	    font-size: 1.25rem;
	    line-height: 1.5;
	    border-radius: 20rem;
	}
	&-sm {
		padding: 0.5rem 2rem;
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: 20rem;
	}

		
}

/*inputs*/
.form-control {
	border-radius: 20px;
}

/*-----Fonts-----*/
.font{
	&-regular {
		font-family:$font-regular !important;
	}
	&-bold {
		font-family:$font-bold;
	}
	&-extrabold {
		font-family:$font-extrabold;
	}
	&-semibold {
		font-family:$font-semibold;
	}
	&-secondary {
		font-family:$font-secondary;
		font-weight: 300;
	}
	&-quote {
		font-family: 'Bodoni Moda', serif;
	}
	&-sav {
		font-family: 'Savoye LET Plain';
	}
}

/*-----Background-----*/
.bg{
	&-dark {
		* {
			color: $white;
		}
	}
	&-dark-2 {
		background: $dark-2;
		* {
			color: $white;
		}
	}
	&-black {
		background-color: $black;
		
	}
	&-secondary {
		background-color: $bg-secondary !important;
	}
	&-primary-3 {
		background: $primary-3 !important;
	}
	&-secondary-3 {
		background: $secondary-3 !important;
	}
	&-main {
		background: #f4dacd;
	}
}
.c {
	&-primary {
		color: $primary !important;
	}
	&-muted {
		color: $muted !important;
	}
	&-secondary {
		color: $secondary-2 !important;
	}
	&-white {
		color: white !important;
	}
	&-fb {
		color: $bg-facebook;
	}
}
.imagebg {
    position: relative;
    .container {
		z-index: 2;
	  	position: relative;
	}
	* {
		color: $white;
	}
}
.background-image-holder {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity .3s linear;
  -webkit-transition: opacity .3s linear;
  opacity: 0;
  background: $black;
  &:not([class*='col-']) {
	  width: 100%;
	}
  img {
  	display: none;
  }
}
.cover-fullscreen {
  min-height: 100vh;
}
.height-100  {
  height: 100vh;
}
.pos-vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.hidden {
	&-xs {
		@include media($xs){
			display: none;
		}
	}
	&-sm {
		@include media($sm...){
			display: none;
		}
	}
	&-md {
		@include media($md...){
			display: none;
		}
	}
	&-lg {
		@include media($lg){
			display: none;
		}
	}
}
.visible {
	&-xs {
		display: none;
		@include media($xs){
			display: block !important;
		}
	}
	&-sm {
		display: none;
		@include media($sm...){
			display: block !important;
		}
	}
	&-md {
		display: none;
		@include media($md...){
			display: block !important;
		}
	}
	&-lg {
		display: none;
		@include media($lg){
			display: block !important;
		}
	}
}

.highlight {
	padding: 2em;
	border-radius: 20px;
}
/*-----Testimonials-----*/
.testimonial {
  position: relative;
  overflow: hidden;
}

#wrapper {
  width: 100%;
  margin: auto;
  background-color: transparent;
  overflow: unset !important;
}

#sticky {
  position: sticky !important;
  position: -webkit-sticky;
  background: #f4dacd;
  border-top: 1px solid #888;
  width: 100%;
  padding: 20px 50px 20px 50px;
  top: 300px;
}

.sticky-footer {
position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  z-index: 9999;
  text-align: center;
}

.sticky-banner {
	position: fixed;
	background-image: linear-gradient(to top, rgba(165,32,45,1), rgba(220,63,83,1));
	width: 53px;
	z-index: 9999;
	top: 250px;
  	right: 0px;
  	padding: 15px 8px;
  	border-top-left-radius: 20px;
  	border-bottom-left-radius: 20px;
  	line-height: 16px;
  	a {
  		font-size: 15px;
  		
  	}
}


.sticky-flower {
	position: fixed;
	width: 65px;
	z-index: 99999;
	top: 180px;
  	right: -10px;
  	padding: 10px;
}




/*-----Footer-----*/
.subscription {
	/*background-image: linear-gradient(to bottom right, rgba(40,133,169,1), rgba(32,108,131,1));*/
	background-image: linear-gradient(to bottom right, rgba(100,148,0,1), rgba(61,90,0,1));
	padding: 1.7em;
	&-container {
		display: flex;
		flex-wrap: wrap;
		align-items:center;
		justify-content: space-around;
		
	}
	@include media($xs){
		padding: 2em 1.5em
	}
	* {
		color: #f4f4f4;
	}
	input {
		border: 1px solid #f4f4f4;
		background-color: #446519;
		height: 40px;
    	flex: 0 0 25%;
    	border-radius: 20px;
    	color: #f4f4f4;
    	&:focus {
    		outline: 1px solid #ccc;
    	}
    	@include media($xs){
    		flex: 0 0 100%;
    	}
    	&::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		  color: #f4f4f4 !important;
		  opacity: 0.8; /* Firefox */
		}
	}
	button {
		color: $white;
		@include media($xs){
    		width: 100%;
    	}
	}
}
footer {
	padding: 2rem;
	
	.logo {
		max-height: 5rem !important;
	}
	.navbar-nav {
		display: flex;
		  flex-direction: row;
		  flex-wrap: wrap;
		  width: 100%;

		.nav-link {
			padding-right: 0.6em;
			padding-left: 0.5em;
    		font-family: $font-body;
		    text-transform: uppercase;
		    font-size: 0.9em;
		}
		@include media($xs){
			margin-top: 2em;
			li {
				display: flex;
			  	flex-direction: column;
			  	flex-basis: 100%;
			  	flex: 0 0 50%;
			}
		}
	}

	&.bg-dark {
		p,span, .link {
			font-family: $font-body;
			color: $white;
			opacity: .7;
		}
		hr {
			border-top: 1px solid rgba(255,255,255,0.2);
		}
		.nav-link, .link {
			font-family: $font-body;
			color: $white;
			opacity: .7;
			&:hover {
				color: $primary;
				opacity: 1;
			} 
		}
		.font-secondary {
			font-family:$font-secondary;
			font-size: 1.4em;
			font-weight: 400;
		}
	}
	
}

/*flower*/

.flower {
	position: absolute;
    float: right;
    right: -15px;
    top: 60px;
    width: 60%;
    z-index: 999;
}

@media screen and (max-width: 767px) {
  .flower {
	top: 80px;
    width: 50%;
	}
}

/*wizard*/

.wizard-content-left h1 {
  color: #ffffff;
  font-size: 38px;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
}

.form-wizard {
  padding: 30px;
}
.form-wizard .wizard-form-radio {
  display: inline-block;
  margin-left: 5px;
  position: relative;
}
.form-wizard .wizard-form-radio input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #dddddd;
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.form-wizard .wizard-form-radio input[type="radio"]:focus {
  outline: 0;
}
.form-wizard .wizard-form-radio input[type="radio"]:checked {
  background-color: #fb1647;
}
.form-wizard .wizard-form-radio input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;
  left: 1px;
  right: 0;
  margin: 0 auto;
  top: 8px;
}
.form-wizard .wizard-form-radio input[type="radio"]:checked::after {
  content: "";
  display: inline-block;
  -webkit-animation: click-radio-wave 0.65s;
  -moz-animation: click-radio-wave 0.65s;
  animation: click-radio-wave 0.65s;
  background: #000000;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
  border-radius: 50%;
}
.form-wizard .wizard-form-radio input[type="radio"] ~ label {
  padding-left: 10px;
  cursor: pointer;
}
.form-wizard .form-wizard-header {
  text-align: center;
}
.form-wizard .form-wizard-next-btn, .form-wizard .form-wizard-previous-btn, .form-wizard .form-wizard-submit {
  background-color: #d65470;
  color: #ffffff;
  display: inline-block;
  min-width: 100px;
  min-width: 120px;
  padding: 10px;
  text-align: center;
}
.form-wizard .form-wizard-next-btn:hover, .form-wizard .form-wizard-next-btn:focus, .form-wizard .form-wizard-previous-btn:hover, .form-wizard .form-wizard-previous-btn:focus, .form-wizard .form-wizard-submit:hover, .form-wizard .form-wizard-submit:focus {
  color: #ffffff;
  opacity: 0.6;
  text-decoration: none;
}
.form-wizard .wizard-fieldset {
  display: none;
}
.form-wizard .wizard-fieldset.show {
  display: block;
}
.form-wizard .wizard-form-error {
  display: none;
  background-color: #d70b0b;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.form-wizard .form-wizard-previous-btn {
  background-color: #fb1647;
}
.form-wizard .form-control {
  font-weight: 300;
  height: auto !important;
  padding: 15px;
  color: #888888;
  background-color: #f1f1f1;
  border: none;
}
.form-wizard .form-control:focus {
  box-shadow: none;
}

.form-wizard .wizard-form-text-label {
  position: absolute;
  left: 10px;
  top: 16px;
  transition: 0.2s linear all;
}
.form-wizard .focus-input .wizard-form-text-label {
  color: #dd2364;
  top: -18px;
  transition: 0.2s linear all;
  font-size: 12px;
}
.form-wizard .form-wizard-steps {
  margin: 30px 0;
}
.form-wizard .form-wizard-steps li {
  width: 25%;
  float: left;
  position: relative;
}
.form-wizard .form-wizard-steps li::after {
  background-color: #f3f3f3;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}
.form-wizard .form-wizard-steps li span {
  background-color: #dddddd;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  position: relative;
  text-align: center;
  width: 40px;
  z-index: 1;
}
.form-wizard .form-wizard-steps li:last-child::after {
  width: 50%;
}
.form-wizard .form-wizard-steps li.active span, .form-wizard .form-wizard-steps li.activated span {
  background-color: #dd2364;
  color: #ffffff;
}
.form-wizard .form-wizard-steps li.active::after, .form-wizard .form-wizard-steps li.activated::after {
  background-color: #dd2364;
  left: 50%;
  width: 50%;
  border-color: #dd2364;
}
.form-wizard .form-wizard-steps li.activated::after {
  width: 100%;
  border-color: #dd2364;
}
.form-wizard .form-wizard-steps li:last-child::after {
  left: 0;
}
.form-wizard .wizard-password-eye {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
@keyframes click-radio-wave {
  0% {
    width: 25px;
    height: 25px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    width: 60px;
    height: 60px;
    margin-left: -15px;
    margin-top: -15px;
    opacity: 0.0;
  }
}

/*date */

time.icon
{
  font-size: 0.6em; /* change icon size */
  display: block;
  position: relative;
  width: 7em;
  height: 7em;
  background-color: #fff;
  border-radius: 0.6em;
  border: solid 1px #bdbdbd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}

time.icon *
{
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time.icon strong
{
  position: absolute;
  top: 0;
  padding: 0.4em 0;
  color: #fff;
  background-color: #dd2364;
  border-bottom: 1px dashed #fff4ee;
  box-shadow: 0 2px 0 #dd2364;
}

time.icon em
{
  position: absolute;
  bottom: 0em;
  color: #dd2364;
}

time.icon span
{
  width: 100%;
  font-size: 2.8em;
  letter-spacing: -0.05em;
  padding-top: 0.8em;
  color: #2f2f2f;
}

time.icon:hover, time.icon:focus
{
  -webkit-animation: swing 0.6s ease-out;
  animation: swing 0.6s ease-out;
}

.vl {
    width: 1px;
    height: 500px;
    border-left: 1px solid #ccc;
    text-align: center;
    display: inline-block;
    margin-left: 50%;
}

.wrapper-or {
    position: relative;
    width: auto;
    height: 400px;
    margin: 10px;
}

.line {
    position: absolute;
    left: 49%;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #ccc;
    z-index: 1;
}

.wordwrapper {
    text-align: center;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -12px;
    z-index: 2;
}

.word {
    color: #bbb;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 3px;
    font: bold 12px;
    background: #fff;
}

/*.open-soon {
	position: absolute;
}*/

/*bumper*/

 #carousel_spinner{
    z-index:999999;
    display:block;
    position:fixed;
    top:0;
    left:0;
    width:50%;
    height:80%;
    background:url('/img/TLG-Bumper-Image.jpg') no-repeat; 
    }

@media screen and (max-width: 767px) {
  .wizard-content-left {
    height: auto;
  }

  .footer-sm {
  	width: 50% !important;
  }

  .btn-xs-lg {
	padding: 0.4rem 2rem;
	}
}

/*column*/


.col-md-15{
	position: relative;
	min-height: 1px;
	padding-right: 10px;
	padding-left: 10px;
}

@media (min-width: 992px) {
.col-md-15 {
    width: 16.5%;
    float: left;
}
}

.card-success {
	color: #1b9e3e;
}
.card-error {
	color: #9f191f;
}

#card-element {
	border-radius: 4px;
	padding: 12px;
	border: 1px solid rgba(50, 50, 93, 0.1);
	height: 44px;
	width: 100%;
	background: #dd2364;
	color: #fff;
}