// Fonts
@font-face {
    font-family: 'Powerful';
    src: url('/fonts/powerful/Powerful-Regular.eot');
    src: url('/fonts/powerful/Powerful-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/powerful/Powerful-Regular.woff2') format('woff2'),
        url('/fonts/powerful/Powerful-Regular.woff') format('woff'),
        url('/fonts/powerful/Powerful-Regular.svg#Powerful-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Averta';
    src: url('/fonts/averta/Averta-Regular.eot');
    src: url('/fonts/averta/Averta-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/averta/Averta-Regular.woff2') format('woff2'),
        url('/fonts/averta/Averta-Regular.woff') format('woff'),
        url('/fonts/averta/Averta-Regular.svg#Averta-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta Black';
    src: url('/fonts/averta/Averta-Black.eot');
    src: url('/fonts/averta/Averta-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/averta/Averta-Black.woff2') format('woff2'),
        url('/fonts/averta/Averta-Black.woff') format('woff'),
        url('/fonts/averta/Averta-Black.svg#Averta-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta Bold';
    src: url('/fonts/averta/Averta-Bold.eot');
    src: url('/fonts/averta/Averta-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/averta/Averta-Bold.woff2') format('woff2'),
        url('/fonts/averta/Averta-Bold.woff') format('woff'),
        url('/fonts/averta/Averta-Bold.svg#Averta-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta Semibold';
    src: url('/fonts/averta/Averta-Semibold.eot');
    src: url('/fonts/averta/Averta-Semibold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/averta/Averta-Semibold.woff2') format('woff2'),
        url('/fonts/averta/Averta-Semibold.woff') format('woff'),
        url('/fonts/averta/Averta-Semibold.svg#Averta-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta Extrabold';
    src: url('/fonts/averta/Averta-ExtraBold.eot');
    src: url('/fonts/averta/Averta-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/averta/Averta-ExtraBold.woff2') format('woff2'),
        url('/fonts/averta/Averta-ExtraBold.woff') format('woff'),
        url('/fonts/averta/Averta-ExtraBold.svg#Averta-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta Light';
    src: url('/fonts/averta/Averta-Light.eot');
    src: url('/fonts/averta/Averta-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/averta/Averta-Light.woff2') format('woff2'),
        url('/fonts/averta/Averta-Light.woff') format('woff'),
        url('/fonts/averta/Averta-Light.svg#Averta-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Savoye LET Plain';
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/SavoyeLETPlain.woff') format('woff');
    }